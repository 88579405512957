import { CopySvg, FacebookSvg, InfoSvg, TwitterSvg } from "../../assets/svg"
import React from "react"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import useClipboard from "react-use-clipboard"
import { useTranslation } from "next-i18next"
import clsx from "clsx"
import { twMerge } from "tailwind-merge"

interface ShareSocialProps {
    onOpenSharePopup?: () => void
    pageUrl: string

    shortPageUrl: string
}

export const ShareSocial = ({ onOpenSharePopup, pageUrl, shortPageUrl }: ShareSocialProps) => {
    const [isCopied, setCopied] = useClipboard(shortPageUrl, { successDuration: 2000 })

    const { t: tCommon } = useTranslation("common")
    const { t: tGameDetails } = useTranslation("game-details")

    return (
        <div className="absolute right-0 mt-[-26px] z-40 hidden font-normal text-white group-hover:block">
            <div className="-mt-[50%] pb-[20px]">
                <div
                    className={twMerge(
                        clsx(
                            "h-[104px] w-[257px] rounded-[4px] bg-neutral-1-500 px-[18px] pt-[5px] shadow-[0px_4px_4px_rgba(0,0,0,0.25)]",
                            !onOpenSharePopup && "w-[200px]"
                        )
                    )}
                >
                    <div className="mb-[8px] text-sm">{tGameDetails("share.shareVia")}</div>
                    <div className="flex justify-between child:relative child:cursor-pointer child:duration-100 child:ease-in child-hover:translate-y-[-5px]">
                        {onOpenSharePopup && (
                            <div className="hover:z-10 hover-apply-child:flex">
                                <div
                                    className="b-flex-center h-[50px] w-[50px] rounded-[11px] bg-accent-1"
                                    onClick={onOpenSharePopup}
                                >
                                    <InfoSvg width={23} height={23} />
                                </div>
                                <div className="mt-[5px] text-center text-ssm">{tGameDetails("share.info")}</div>
                                <div className="b-flex-center b-inset-center p-hover top-auto -bottom-[4px] hidden h-[19px] w-[98px] translate-y-0 rounded-[2px] bg-neutral-1-800 text-sm text-neutral-1-600">
                                    <div className="absolute -top-[2px] z-20 h-[6px] w-[6px] rotate-45 bg-neutral-1-800"></div>
                                    {tGameDetails("share.gameInformation")}
                                </div>
                            </div>
                        )}
                        <div className="hover:z-10 hover-apply-child:flex">
                            <div className="b-flex-center h-[50px] w-[50px] rounded-[11px] bg-accent-4-800">
                                <TwitterShareButton
                                    url={shortPageUrl}
                                    title={
                                        "Join me for this prediction game on @bbb_wtfpusu Click the link to enter this game now!"
                                    }
                                    hashtags={["BBB_PutUpOrShupUp"]}
                                >
                                    <TwitterSvg width={29} height={25} className="fill-white" />
                                </TwitterShareButton>
                            </div>
                            <div className="mt-[5px] text-center text-ssm">{tGameDetails("share.twitter")}</div>
                            <div className="b-flex-center b-inset-center p-hover top-auto -bottom-[4px] hidden h-[19px] w-[68px] translate-y-0 rounded-[2px] bg-neutral-1-800 text-sm text-neutral-1-600">
                                <div className="absolute -top-[2px] z-20 h-[6px] w-[6px] rotate-45 bg-neutral-1-800"></div>
                                {tGameDetails("share.twitter")}
                            </div>
                        </div>
                        <div className="hover:z-10 hover-apply-child:flex">
                            <div className="b-flex-center h-[50px] w-[50px] rounded-[11px] bg-accent-4-800">
                                <FacebookShareButton url={shortPageUrl} hashtag={"#BBB_PutUpOrShupUp"}>
                                    <FacebookSvg width={26} height={26} />
                                </FacebookShareButton>
                            </div>
                            <div className="mt-[5px] text-center text-ssm">{tGameDetails("share.facebook")}</div>
                            <div className="b-flex-center b-inset-center p-hover top-auto -bottom-[4px] hidden h-[19px] w-[68px] translate-y-0 rounded-[2px] bg-neutral-1-800 text-sm text-neutral-1-600">
                                <div className="absolute -top-[2px] z-20 h-[6px] w-[6px] rotate-45 bg-neutral-1-800"></div>
                                {tGameDetails("share.facebook")}
                            </div>
                        </div>
                        <div className="hover:z-10 hover-apply-child:flex">
                            <div
                                className="b-flex-center h-[50px] w-[50px] rounded-[11px] bg-primary"
                                onClick={setCopied}
                            >
                                <CopySvg width={18} height={20} />
                            </div>
                            <div className="mt-[5px] text-center text-ssm">{tGameDetails("share.copyURL")}</div>
                            <div
                                className={`b-flex-center b-inset-center p-hover top-auto -bottom-[4px] hidden h-[19px] w-[102px] translate-y-0 rounded-[2px] bg-neutral-1-800 text-sm text-neutral-1-600 ${
                                    isCopied ? "w-[54px]" : ""
                                }`}
                            >
                                <div className="absolute -top-[2px] z-20 h-[6px] w-[6px] rotate-45 bg-neutral-1-800"></div>
                                {isCopied ? tCommon("copied") : tCommon("copyToClipboard")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
