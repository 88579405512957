import axios from "axios"
import { GameType } from "./games"
import { ApiUrl } from "../utils/network"

export async function getWatchGames(
    gameType: GameType,
    page: number = 1,
    limit: number = 20,
    sortField: string = "createdAt",
    sortDirection: number = -1
) {
    const url = `${ApiUrl}/api/v1/watch-lists`

    return axios.get(url, {
        params: {
            gameType: gameType,
            orderBy: sortField,
            order: sortDirection,
            page: page,
            limit: limit
        }
    })
}

export async function watchGame(gameId) {
    const url = `${ApiUrl}/api/v1/watch-lists`

    return axios.post(url, { game: gameId })
}
