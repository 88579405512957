import React from "react"
import close from "../../assets/imgs/home/bg_x.png"
import bg_thunder_win from "../../assets/imgs/game-details/bg_thunder_win.png"
import StyledModal, { ModalProps } from "../StyledModal"
import { formatUnixTimestampLong } from "../../utils/date"
import { formatNumber, toTrunc } from "../../utils/number"
import QRCode from "react-qr-code"

import reject_mask from "../../assets/imgs/game-details/reject-mask.png"
import cross_mask from "../../assets/imgs/game-details/cross-mask.png"
import clsx from "clsx"
import { twMerge } from "tailwind-merge"
import { useTranslation } from "next-i18next"
import { getResultText } from "../../utils/string"

export enum PopupType {
    ONGOING = "ONGOING",
    WIN = "WIN",
    LOSE = "LOSE",
    REJECT = "REJECT"
}

interface ISharingPopup extends ModalProps {
    gameDetails: any
    pageUrl: string
}

export const SharingPopup = ({ isOpen, onRequestClose, gameDetails, pageUrl }: ISharingPopup) => {
    const { t: tCommon } = useTranslation("common")
    const { t: tGameDetails } = useTranslation("game-details")

    const { uid, title, expired, status, selections, playerBet, result, volume, mainImage, tokenName } = gameDetails
    let type = PopupType.ONGOING

    if (status == 7 && playerBet) {
        type = playerBet.status === 2 ? PopupType.LOSE : PopupType.WIN
    } else if (status === 3) {
        type = PopupType.REJECT
    }

    const statusType = (type) => {
        switch (type) {
            case PopupType.ONGOING:
                return tGameDetails("share.onGoingStatus")

            case PopupType.WIN:
                return tGameDetails("share.winStatus")

            case PopupType.REJECT:
                return tGameDetails("share.rejectStatus")

            default:
                return tGameDetails("share.loseStatus")
        }
    }

    const getUnion = (type) => {
        switch (type) {
            case PopupType.ONGOING:
                return (
                    <div className="absolute bottom-[-5px] left-0 z-[1] h-[13px] w-full bg-[url(../assets/imgs/game-details/bg_ongoing.png)] bg-cover bg-left bg-no-repeat"></div>
                )

            case PopupType.WIN:
                return (
                    <div className="absolute bottom-[-5px] left-0 z-[1] h-[13px] w-full bg-[url(../assets/imgs/game-details/bg_win.png)] bg-cover bg-left bg-no-repeat"></div>
                )

            case PopupType.REJECT:
                return (
                    <div className="absolute bottom-[-5px] left-0 z-[1] h-[13px] w-full bg-[url(../assets/imgs/game-details/bg_reject.png)] bg-cover bg-left bg-no-repeat"></div>
                )

            case PopupType.LOSE:
                return (
                    <div className="absolute bottom-[-5px] left-0 z-[1] h-[13px] w-full bg-[url(../assets/imgs/game-details/bg_lose.png)] bg-cover bg-left bg-no-repeat"></div>
                )
        }
    }

    const getContent = (type) => {
        switch (type) {
            case PopupType.ONGOING:
                return (
                    <div className="flex flex-wrap">
                        {selections &&
                            selections.map((selection, index) => {
                                return (
                                    <div className="w-full lg:w-[50%]" key={selection.name}>
                                        <dl className="mb-[5px] flex text-xs leading-5 text-neutral-1-600 ">
                                            <dt className="w-[60%]">
                                                {tGameDetails("share.putFor", { selection: selection.name })}
                                            </dt>
                                            <dd className="w-[40%] text-neutral-1-650">-</dd>
                                        </dl>
                                        <dl className="mb-[5px] flex text-xs leading-5 text-neutral-1-600">
                                            <dt className="w-[60%]">{tGameDetails("share.bankerPool")}</dt>
                                            <dd className="w-[40%] text-neutral-1-650">
                                                {selection.totalBet} {tokenName}
                                            </dd>
                                        </dl>
                                        <dl className="mb-[15px] flex text-xs leading-5 text-neutral-1-600">
                                            <dt className="w-[60%]">{tGameDetails("share.currentBankerOdd")}</dt>
                                            <dd className="w-[40%] text-neutral-1-650">
                                                1:{toTrunc(selection.currentOdds + 1, 2)}
                                            </dd>
                                        </dl>
                                    </div>
                                )
                            })}
                        <div className="w-full lg:w-[60%]">
                            <dl className="mb-[5px] flex text-xs leading-5 text-neutral-1-600">
                                <dt className="w-[50%]">{tGameDetails("share.deadline")}</dt>
                                <dd className="w-[50%] text-neutral-1-650">{formatUnixTimestampLong(expired)}</dd>
                            </dl>
                            <dl className="mb-[5px] flex text-xs leading-5 text-neutral-1-600">
                                <dt className="w-[50%]">{tGameDetails("share.totalStacked")}</dt>
                                <dd className="w-[50%] text-neutral-1-650">
                                    {formatNumber(volume)} {tokenName}
                                </dd>
                            </dl>
                        </div>
                    </div>
                )

            case PopupType.WIN:
                return (
                    <div className="relative py-5 text-center font-primary font-bold lg:py-0">
                        <div className="absolute left-[-97px] top-[-160px] hidden w-[668px] lg:block">
                            <img src={bg_thunder_win.src} />
                        </div>
                        <div className="relative">
                            <div className="absolute top-[-10px] left-0 z-10 block h-full w-full scale-[155%] bg-[url(../assets/imgs/game-details/bg_thunder_win_2.png)] bg-contain bg-center bg-no-repeat lg:hidden"></div>
                            <div className="absolute top-[10px] left-0 block h-full w-full scale-[145%] bg-[url(../assets/imgs/game-details/bg_win_text.png)] bg-contain bg-center bg-no-repeat lg:hidden"></div>
                            <div className="absolute bottom-0 left-0 h-full w-full scale-[130%] bg-[url(../assets/imgs/game-details/cup_win.png)] bg-contain bg-[bottom_20px_center] bg-no-repeat lg:scale-100"></div>
                            <div className="relative z-10 mx-auto h-[51px] w-[181px] bg-[url(../assets/svg/bg/bg_result.svg)] text-xl leading-[47px] text-accent-2">
                                {tGameDetails("share.result", { result: tCommon(getResultText(result, selections)) })}
                            </div>
                            <p className="relative z-10 mt-2 text-xl font-bold uppercase text-neutral-1-600">
                                {tGameDetails("share.wonOdd")}
                            </p>
                            <p className="relative z-10 text-4xl leading-[1] text-primary">
                                {playerBet.winOddsPercent}%
                            </p>
                        </div>
                    </div>
                )

            case PopupType.LOSE:
                return (
                    <div className="py-5 text-center font-primary font-bold lg:py-0">
                        <div className="relative">
                            <div className="absolute top-0 left-0 block h-full w-full scale-[130%] bg-[url(../assets/imgs/game-details/bg_lose_text.png)] bg-contain bg-center bg-no-repeat lg:hidden"></div>
                            <div className="relative z-10 mx-auto h-[51px] w-[181px] bg-[url(../assets/svg/bg/bg_result.svg)] text-xl leading-[47px] text-accent-2">
                                {tGameDetails("share.result", { result: tCommon(getResultText(result, selections)) })}
                            </div>
                            <p className="relative z-10 mt-2 text-xl font-bold uppercase text-neutral-1-600">
                                {tGameDetails("share.loseOdd")}
                            </p>
                            <p className="relative z-10 text-4xl leading-[1] text-neutral-1-600">
                                {playerBet.winOddsPercent}%
                            </p>
                        </div>
                    </div>
                )

            case PopupType.REJECT:
                return (
                    <div className={"mb-6"}>
                        <div className={"text-center text-3xl font-bold uppercase leading-[56px] text-accent-3"}>
                            {tGameDetails("share.terminate")}
                        </div>
                        <div className={"relative flex justify-between"}>
                            <div
                                className={
                                    "absolute left-[-30px] top-[-60px] w-[60px] lg:top-[-40px] lg:left-[-20px] lg:w-auto"
                                }
                            >
                                <img src={reject_mask.src} />
                            </div>
                            <div
                                className={
                                    "b-flex-center m-auto h-[90px] w-[280px] bg-[url(../assets/imgs/bg/text-dark.png)] text-center text-xl  font-bold leading-7 text-neutral-1-100"
                                }
                            >
                                <div className={"max-w-[184px]"}>{tGameDetails("share.rejectReason")}</div>
                            </div>
                            <div
                                className={
                                    "absolute right-[-30px] top-[-60px] w-[60px] lg:top-[-40px] lg:right-[-20px] lg:w-auto"
                                }
                            >
                                <img src={cross_mask.src} />
                            </div>
                        </div>
                    </div>
                )
        }
    }

    return (
        <div>
            <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
                <div className={`${type === PopupType.WIN ? "lg:px-[50px]" : "lg:px-[40px]"} relative px-5 pt-3`}>
                    <div className="ml-auto table cursor-pointer pt-[9px]">
                        <img
                            src={close.src}
                            onClick={onRequestClose}
                            className="w-[15px] hover:opacity-50 lg:w-[20px]"
                        />
                    </div>
                    <div
                        className={twMerge(
                            clsx(
                                "relative w-full max-w-full rounded-[5px] bg-primary py-5 pb-0 font-primary text-neutral-1-600 lg:w-[565px]",
                                type === PopupType.WIN && "bg-accent-2",
                                type === PopupType.LOSE && "bg-neutral-1-300",
                                type === PopupType.REJECT && "bg-accent-3"
                            )
                        )}
                    >
                        <div className="absolute left-[-36px] top-[-47px] h-[100px] w-[134px] bg-[url(../assets/imgs/game-details/status.png)] font-secondary text-xs ">
                            <span className="absolute left-[50%] top-[46%] translate-x-[-50%] translate-y-[-50%] rotate-[-24.85deg] whitespace-nowrap">
                                {statusType(type)}
                            </span>
                        </div>
                        <div
                            className={twMerge(
                                clsx(
                                    "relative px-10 pb-5 text-xl font-bold text-neutral-1-600 lg:text-2xl",
                                    type === PopupType.ONGOING && "text-accent-1",
                                    type === PopupType.REJECT && "text-neutral-1-100"
                                )
                            )}
                        >
                            {title}
                            {getUnion(type)}
                        </div>
                        <div className="relative bg-[url(../assets/imgs/bg/bbb-white-big.png)] bg-cover bg-no-repeat px-10 py-5 font-primary">
                            <div className="absolute left-0 top-[47px] block h-[68px] w-full bg-neutral-1-800 content-[''] lg:hidden"></div>
                            <p className="mb-2 text-base font-bold text-neutral-1-600 lg:text-xl">
                                {tGameDetails("share.sequence")} <span className="ml-2 text-neutral-1-650">{uid}</span>
                            </p>
                            <div className="relative mb-4 h-[131px] child:relative child:z-10">
                                <img
                                    src={mainImage}
                                    className={`${
                                        type === PopupType.LOSE ? "grayscale" : ""
                                    } h-full w-full object-cover`}
                                />
                            </div>
                            {getContent(type)}
                        </div>
                        {getUnion(type)}
                    </div>
                    <div className="rounded-b-[5px] bg-neutral-1-500">
                        <div className="flex h-[130px] justify-center">
                            <div className="flex flex-col items-center justify-center pt-2">
                                <div className={"rounded-md bg-white p-2"}>
                                    <QRCode
                                        size={67}
                                        style={{ height: "100%", maxWidth: "100%", width: "100%" }}
                                        value={pageUrl}
                                    />
                                </div>
                                <p className="mt-2 text-xs text-accent-1">{tGameDetails("share.joinGameQrCode")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledModal>
        </div>
    )
}
