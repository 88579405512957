import { QRCodeSvg, ShareSvg, StarSvg } from "../../assets/svg"
import React, { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import clsx from "clsx"
import { SharingPopup } from "./SharingPopup"
import StyledModal from "../StyledModal"
import close from "../../assets/imgs/home/bg_x.png"
import QRCode from "react-qr-code"
import { watchGame } from "../../services/gameWatch"
import useGlobalStore from "../../hooks/useGlobalStore"
import { ShareSocial } from "./ShareSocial"
import { twMerge } from "tailwind-merge"

interface ShareProps {
    gameDetails: any
    pageUrl: string
    shortPageUrl: string
    className?: string
    starClassName?: string
    shareClassName?: string
    qrCodeClassName?: string
    isHideStar?: boolean
    isHideShare?: boolean
    isHideQrCode?: boolean
}

export const Share = ({
    gameDetails,
    pageUrl,
    shortPageUrl,
    className,
    starClassName,
    shareClassName,
    qrCodeClassName,
    isHideStar,
    isHideShare,
    isHideQrCode
}: ShareProps) => {
    const { id, isWatched } = gameDetails

    const { t: tGameDetails } = useTranslation("game-details")

    const { setIsOpenLoginModal, isLoggedIn } = useGlobalStore()

    const [modalIsOpen, setIsOpen] = React.useState(false)

    function openModal() {
        setIsOpen(true)
    }

    function closeModal() {
        setIsOpen(false)
    }

    const [sharingModalIsOpen, setSharingModalIsOpen] = useState(false)
    const openSharingModal = () => {
        setSharingModalIsOpen(true)
    }
    const closeSharingModal = () => {
        setSharingModalIsOpen(false)
    }

    const [isWatch, setIsWatch] = useState(isWatched)

    const onWatch = async () => {
        if (!isLoggedIn) {
            setIsOpenLoginModal(true)

            return
        }

        await watchGame(id)

        setIsWatch((previousValue) => !previousValue)
    }

    useEffect(() => {
        setIsWatch(isWatched)
    }, [isWatched])

    return (
        <>
            <SharingPopup
                gameDetails={gameDetails}
                pageUrl={shortPageUrl}
                isOpen={sharingModalIsOpen}
                onRequestClose={closeSharingModal}
            />
            <StyledModal isOpen={modalIsOpen} onRequestClose={closeModal}>
                <div className="m-auto h-[480px] w-[90%] rounded-t-[5px] bg-[url(../assets/imgs/bg/bbb-white-big.png)] bg-cover bg-no-repeat text-center lg:w-[432px]">
                    <div className="pt-[9px] text-right">
                        <img
                            src={close.src}
                            onClick={closeModal}
                            className="float-right mr-[12px] w-[20px] hover:opacity-80"
                        />
                    </div>
                    <div className="pt-[44px] text-3xl font-bold text-neutral-1-500">
                        {tGameDetails("share.scanQRCode")}
                    </div>
                    <div className="mt-[10px] text-base text-neutral-1-500">
                        {tGameDetails("share.scanQRCodeDescription")}
                    </div>
                    <div className="b-flex-center m-auto mt-[35px] h-[233px] w-[168px] flex-col rounded-[8px] bg-neutral-1-100">
                        <div className="h-[149px] w-[149px]">
                            <QRCode
                                style={{ height: "100%", maxWidth: "100%", width: "100%" }}
                                value={shortPageUrl}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <div className="mt-[16px] text-xl text-neutral-1-500">{tGameDetails("share.scanMe")}</div>
                    </div>
                </div>
            </StyledModal>
            <div className={twMerge(clsx("mr-4 mt-[23px] flex items-center justify-end", className))}>
                {!isHideStar && (
                    <div>
                        <StarSvg
                            width={17}
                            height={17}
                            className={twMerge(clsx(isWatch && "fill-accent-1 stroke-accent-1"), starClassName)}
                            onClick={onWatch}
                        />
                    </div>
                )}
                {!isHideShare && (
                    <div className="group relative ml-3">
                        <ShareSvg
                            width={17}
                            height={17}
                            className={twMerge("group-hover:fill-accent-1", shareClassName)}
                        />
                        <ShareSocial
                            pageUrl={pageUrl}
                            shortPageUrl={shortPageUrl}
                        />
                    </div>
                )}
                {!isHideQrCode && (
                    <div className="ml-3">
                        <QRCodeSvg
                            width={17}
                            height={17}
                            onClick={openModal}
                            className={twMerge("hover:fill-accent-1", qrCodeClassName)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}
